#order_details {
    /*height: 140px;*/
    padding: 10px;
    width: 100%;
    margin: auto;
    border-radius: 2px;
    background-color: #E3E3E3;
}

.order-details-data {
    font-family: futuraptbold;
    font-size: 16px;
    margin-top: 5px;
}

.selected-month-year {
    text-align: center;
    margin: auto;
    display: block;
}

.previous-arrow {
    float: right;
}

.next-arrow, .previous-arrow {
    font-size: 22px;
    font-weight: bolder;
    font-family: monospace;
    top: -3px;
    position: relative;
}

.selected-month-year {
    font-family: futuraptbold;
    font-size: 18px;
    font-weight: 600;
}

.previous-arrow:hover, .next-arrow:hover {
    cursor: pointer;
}

.date_picker_container div.row {
    margin-left: 0px;
}

.flex-fill {
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.date_bubble_container {
    padding: 0px;
    text-align: center;
}

.futura {
    font-family: futuraptbold;
}

.date_bubble {
    border-radius: 40px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    background-color: black;
    color: white;
    text-align: center;
    border: 1px solid black;
}

.date_bubble_legend {
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background-color: black;
    border: 1px solid black;
    display: inline-block;
}
.date_bubble_legend[data-type="available"] {
    background-color: white;
    border: 1px solid blue;
}
.date_bubble_legend[data-type="unavailable"] {
    border: 1px solid lightgrey;
    background-color: lightgrey;
}
.legend_container {
    font-family: futuraptbold;
    font-size: 14px;
    padding: 0px;
}
.legend_text {
    line-height: 14px;
    position: relative;
    top: -8px;
    margin-left: 2px;
}

.unavailable_legend {
    position: relative;
    left: -10px;
    width: 34%;
}
.available_legend {
    width: calc(66%/2);
}
.selected_legend {
    width: calc(66%/2);
}
.date_bubble[data-status="available"] {
    color: black;
    background-color: white;
    border: 1px solid blue;
}

.date_bubble[data-status="available"]:hover {
    cursor: pointer;
}

.date_bubble[data-status="unavailable"] {
    color: darkslategrey;
    background-color: lightgrey;
    border: 1px solid lightgrey;
}
.date_bubble[data-status="hide"] {
    visibility: hidden;
}

.time-slot {
    text-align: center;
    width: 100%;
    background-color: white;
    color: black;
    border: 1px solid blue;
    border-radius: 8px;
    margin-top: 10px;
    font-family: futuraptbold;
    padding: 8px;
}

.time-slot[data-status="selected"] {
    background-color: black;
    color: white;
    border: 1px solid black;
}

.time-slot[data-status="unavailable"] {
    background-color: lightgrey;
    color: darkslategrey;
    border: 1px solid lightgrey;
}

.time-slot[data-status="available"]:hover {
    cursor: pointer;
}

#lead_time_display_container {
    border: 2px solid black;
    border-radius: 20px;
    padding: 20px;
}

#lead_time_display_container > ol > li:not(:first-child) {
    margin-top: 15px;
}

#lead_time_display_container > ol > li {
    font-weight: 500;
}

ul > li {
    list-style: disc;
    font-weight: 500;
}

.reschedule-continue {
    margin-right: 5px;
}
.reschedule-cancel {
    margin-left: 5px;
}

.reschedule-cancel-error {
    width: 270px;
    display: inline-block;
    position: fixed;
    bottom: 20px;
    text-align: center;
    font-weight: bold;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
}

#delivery_instructions {
    width: 100%;
    height: 150px;
    border: 2px solid black;
    margin-top: 5px;
    border-bottom: 0px !important;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

#delivery_instructions:focus, #delivery_instructions:active {
    border-bottom: 0px;
}

#text_area_subtext {
    width: 100%;
    display: block;
    border-left: 2px solid black;
    border-right: 2px solid black;
    margin-top: -6px;
    font-weight: bold;
    font-size: 8pt;
    text-align: right;
    padding-right: 10px;
    padding-bottom: 10px;
}

#delivery_instructions_container {
    border-bottom: 2px solid black;
}

#reschedule_container[data-active='false'] {
    display: none;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.reschedule-delivery-timer {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 80px;
    text-align: center;
    border-radius: 5px;
    border: 2px solid red !important;
}

.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.ReactModal__Content {
    height: fit-content;
}

/**
Desktop Specific
 */
@media screen and (min-width: 800px) {
    .reschedule-cancel-error {
        top: 680px;
        height: 50px;
    }

    .reschedule-continue {
        width: 170px !important;
        margin-left: 0px;
        margin-right: 5px;
    }
    .reschedule-cancel {
        width: 170px !important;
        margin-left: 5px;
        margin-right: 0px;
    }
    .page-footer-reschedule {
        top: 696px !important;
    }
    #root > div.overflow-hidden {
        height: 3000px;
    }
}