@font-face {
    font-family: "futuraptbold";
    src: url("fonts/Futura PT Heavy.woff2") format("woff2"),
    url("fonts/Futura PT Heavy.woff") format("woff");
}

div, br {
    caret-color: transparent;
}
input, textarea {
    caret-color: black;
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: black !important;
}

.card {
    border: none !important;
}
.square {
    width: 100px;
    height: 100px;
  }

.page-footer {
    text-align: center;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    height: 72px;
    width: 100vw;
    padding: 2px 2px 2px 2px;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.35);
}

.back-button {
    margin: auto;
    width: 156px;
    height: 48px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    border: 1px solid #000000;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: bold;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.38px;
}
.back-button:focus, .back-button:active {
    background-color: #ffffff !important;
}

.continue-button {
    margin: auto;
    width: 156px;
    height: 48px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    background-color: #000000 !important;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.38px;
}

.continue-button[data-disabled="true"] {
    background-color: #777777 !important;
}



.item {
    width: 327px;
    height: 193px;
    padding: 2px 2px 2px 2px;
    border-radius: 8px;
    border: 4px solid #007bc2;
    background-color: rgba(0, 123, 194, 0.25);
    box-sizing: border-box;
}
.rectangle {

    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    background-color: #E3E3E3;
    box-sizing: border-box;
}
.rectangle1 {
    text-align: center;
    padding: 10px;
    border-radius: 3px;
    background-color: rgba(0, 123, 194, 0.25);
    box-sizing: border-box;
}
.storeAddress {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    width: 268px;
    height: 49px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    color: #000000;
    text-align: left;
    line-height: 24px;
    letter-spacing: .5px;
}
#container { white-space: nowrap; }
#party-city-cupertin {
    width: 327px;
    height: 139px;
    padding: 2px 2px 2px 2px;
    border: 1px solid #797979;
    background-color: #ffffff;
    box-sizing: border-box;
}

#location-on {
    width: 13px;
    height: 18px;
    padding: 2px 2px 2px 2px;
    background-color: #000000;
    box-sizing: border-box;
    white-space: nowrap;
}
a:active {
    color: none;
}


.orderDetailsValue {
    letter-spacing: .5px;
    width: 184px;
    height: 26px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: 700;
    color: #000000;
    text-align: left;
    line-height: 26px;
    margin-left: 5px;
}
.store-hours {
    width: 82px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    text-decoration: underline;
    color: #000000;
    text-align: left;
    line-height: 24px;
    letter-spacing: -0.48px;
}

.subHeader {
    width: 118px;
    height: 18px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    color: #000000;
    text-align: center;
    line-height: 18px;
    letter-spacing: -0.66px;
}

.iamhere {
    width: 100%;
    height: 48px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    border: 1px solid #000000;
    background-color: #000000;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    /*letter-spacing: -0.32px;*/
    font-size: 15px;
    border: 0px;
}




.iamonmyway:hover, .iamonmyway:focus {
    background-color: #007bc2 !important;
}

.iamonmyway {
    width: 170px;
    height: 48px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    background-color: #007bc2;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
}

.iamhere[data-enabled="false"], .iamhere[data-active="false"], .iamonmyway[data-active="false"]  {
    background-color: #EAEAEA;
    color:black;
    border:  none;
}
.iamhere[data-enabled="false"]:hover, .iamhere[data-active="false"]:hover, .iamonmyway[data-active="false"]:hover {
    cursor: inherit;
    background-color: #EAEAEA !important;
    color:black!important;
}

.iamhere[data-enabled="false"]:focus, .iamhere[data-active="false"]:focus, .iamonmyway[data-active="false"]:focus {
    cursor: inherit;
    background-color: #EAEAEA !important;
    color:black!important;
}

.iamonmyway[data-visible="false"] {
    display: none;
}

.tellusText {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.6px;
}
.thankYou {
    font-size: 20px;
    width: 327px;
    height: 27px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: 26px;
    letter-spacing: -0.25px;
}
.inStoreOrderDetails {
    font-size: 18px;
    width: 327px;
    height: 46px;
    background-color: rgba(255, 255, 255, 0);
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.6px;
}

.orderInformationHeader {
    width: 327px;
    height: 23px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    color: #717171;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.5px;
}

.orderNumber {
    width: 327px;
    height: 23px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.5px;
}
.feedbacknote {
    font-size: 20px;
    width: 327px;
    height: 73px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #717171;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.6px;
}
#feedbackButton {
    width: 223px;
    height: 48px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    background-color: #000000;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    align-content: center;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.partyPickup {
    width: 105px;
    height: 18px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    color: #000000;
    text-align: center;
    line-height: 18px;
    letter-spacing: -0.66px;
}
.feedbacktextarea {
    width: 327px;
    height: 86px;
    padding: 12px 12px 12px 12px;
    border-radius: 8px;
    border: 1px solid #000000;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    text-align: left;
}
.submitfeedback {
    width: 147px;
    height: 48px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    background-color: #000000;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.step1VehicleInfo {
    width: 327px;
    height: 27px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: ;
    color: #000000;
    text-align: center;
    line-height: 26px;
    letter-spacing: -0.66px;
}

.vehicleInfoSubHeader {
    width: 327px;
    height: 53px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.6px;
}
.vehicleType {
    width: 327px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: left;
    line-height: 24px;
    letter-spacing: -0.48px;
}

.table-scroll {
    position:relative;
    max-width:600px;
    margin:auto;
    overflow:hidden;
    border:none;
}
.table-wrap {
    width:100%;
    overflow:auto;
}
.table-scroll table {
    width:100%;
    margin:auto;

}
.table-scroll th, .table-scroll td {
    padding:5px 10px;
    border:none;
    background:#fff;
    white-space:nowrap;
    vertical-align:top;
}
.table-scroll thead, .table-scroll tfoot {
    background:#f9f9f9;
}
.clone {
    position:absolute;
    top:0;
    left:0;
    pointer-events:none;
}
.clone th, .clone td {
    visibility:hidden
}

.clone tbody th {
    visibility:visible;
    color:red;
}
.clone .fixed-side {
    border:1px solid #000;
    background:#eee;
    visibility:visible;
}
.clone thead, .clone tfoot{background:transparent;}

/**
    PCHI Header
 */

.page-header {
    text-align: center;
    width: 100vw;
    top: -10px;
    left: -10px;
    position: absolute;
    box-sizing: border-box;
    padding: 5px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 35%);
}
#header-text {
    width: 142px;
    height: 18px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    text-align: center;
    line-height: 18px;
    letter-spacing: -0.66px;
    top: -8px;
    position: relative;
}

/**
    Microsite Landing Page
 */



#store-details {
    padding: 10px;
}

#store_hours_toggle {
    /*margin-right: 15px;*/
}

.store-hours {
    margin-right: 10px;
}

.order-details-header {
    width: 58px;
    height: 26px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: 700;
    color: #717171;
    text-align: left;
    line-height: 26px;
    letter-spacing: -0.25px;
}

#switch_method {
    padding: 0px;
}

#switch-type-tooltip {
    background-color: #e87d24 !important;
    opacity: 1;
    color: black;
}

.week-navigation {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: black;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 8px;
}

#week-previous {
    float: left;
}

#week-next {
    float: right;
}

.day[data-today="true"] {
    font-weight: bold;
}

.order-status {
    height: 18px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-size: 18px;
    color: #000000;
    text-align: left;
    line-height: 18px;
    letter-spacing: .5px;
}

.shipment-status {
    border: 2px solid #717171;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 150px;
    height: 140px;
}

.shipment-status[data-half="true"] {
    margin: auto;
}

.shipment-status[data-active="true"] {
    border: 2px solid #018937;
}
.shipment-status[data-show="false"] {
    display: none;
}

.shipment-status-header {
    background-color: #717171;
    color: white;
    text-align: center;
    padding: 3px 2px 2px 2px;

    font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans", sans-serif;
}
.shipment-status-header[data-active="true"] {
    background-color:  #018937;
}

.shipment-status-image {
    text-align: center;
}

.shipment-status-description {
    text-align: center;
    color: #717171;
    line-height: 15px;
    letter-spacing: -0.12px;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
}

.shipment-status-description[data-active="true"] {
    color: #018937;
}
.store-hours-subheader {
    margin-bottom: 8px;
}
.week-range {
    width: 200px;
    height: 18px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #000000;
    text-align: left;
    line-height: 18px;
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
}




/**
    Vehicle Information
 */

#vehicle-list, #color-list {
    overflow: hidden !important;
    overflow-x: scroll !important;
    width: 100%;
    white-space:nowrap;
    padding-left: 10px;
    padding-right: 10px;
}

.vehicle-card {
    background-color: #f2f2f2;
    border: 1px solid black;
    margin-bottom: 10px;
    margin-left: 10px;
    border-radius: 8px;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.vehicle-card.selected {
    background-color: #99d7f1;
    border: 6px solid #009cdb;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
}


.cover-bar {
    position: absolute;
    background: #fff;
    pointer-events: none;
    width: 100%;
    bottom: 0;
    left: 0;
    height: .4em;
    -webkit-transition: all .5s;
    opacity: 1;
}

.cover-bar.hidden {
    opacity: 0;
}


#vehicle-list::-webkit-scrollbar, #color-list::-webkit-scrollbar {
    height: 4px;
    width:4px;
}

#vehicle-list::-webkit-scrollbar-track, #color-list::-webkit-scrollbar-track {
    background: white;

}

#vehicle-list::-webkit-scrollbar-thumb, #color-list::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    background-color: black;
    width: 20% !important;
}

#vehicle-type, #vehicle-color, #vehicle-make-header {
    margin-left: 20px;
    margin-bottom: 10px;
    width: 327px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: left;
    line-height: 24px;
    letter-spacing: -0.48px;
}

.vehicle-description, .color-description {
    margin-bottom: 0px;
}

#vehicle-header {
    width: 327px;
    height: 27px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: 'futuraptbold';
    /*font-weight: bolder;*/
    color: #000000;
    text-align: center;
    line-height: 26px;
    letter-spacing: -0.66px;
    font-size: 22px;
}
#vehicle-information {
    padding-left: 50px;
    padding-right: 50px;
    height: 53px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.6px;
}


  
.color {
    width: 54px;
    height: 54px;
    padding: 2px 2px 2px 2px;
    border-radius: 8px;
    box-sizing: border-box;
}

#color_white {
    background-color: white;
    border: 1px solid black;
}
#color_black {
    background-color: black;
}
#color_silver {
    background-color: #aaaaaa;
}
#color_red {
    background-color:  #d9001b;
}
#color_blue {
    background-color: #007bc2;
}
#color_green {
    background-color: #018937;
}
#color_orange {
    background-color: #e87d24;
}
#color_yellow {
    background-color: #f7f71c;
}
#color_other {
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
}
#color_other.selected {
    background-color: #99d7f1;
}
.color.selected{
    border: 6px solid #009cdb !important;
}

#color-plus {
    margin: auto;
    display: block;
}

#custom-color-header, #custom-vehicle-header {
    margin-bottom: 0px;
    width: 327px;
    height: 27px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #797979;
    text-align: left;
    line-height: 24px;
    letter-spacing: -0.6px;
}

#custom-color, #custom-vehicle, #vehicle-make {
    margin-left: 20px;
    margin-right: 20px;
}

#custom-color[data-active=active], #custom-vehicle[data-active=active] {
    display: block;
}


#custom-color[data-active=inactive], #custom-vehicle[data-active=inactive] {
    display: none;
}

#custom-color-input, #custom-vehicle-input, #vehicle-make-input {
    border-radius: 8px;
    padding: 6px 6px 6px 6px;
    border: 1px solid #000000;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    width: 100%;
    border-radius: 5px;
}

#custom-color-input:focus, #custom-vehicle-input:focus {
    outline: none;
}

/*bag location*/

.bag_center {
    text-align: center;
    padding: .5px;
}

.bag_right {
    text-align: right;
    padding: .5px;
}

.bag_left {
    text-align: left;
    padding: .5px;
}



/*parking info*/

#parking-info-subHeader {
    padding-left: 10px;
    padding-right: 10px;
    height: 23px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.48px;

  }
  #parkinglocationSubHedaer {
    width: 327px;
    height: 49px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.6px;
  }

  #enter-your-space-num {
    width: 327px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.48px;
  }
  #parkingSpace-textbox {
      z-index: 9999999;
      position: relative;
    width: 105px;
    height: 59px;
    padding: 12px 12px 12px 12px;
    border-radius: 8px;
    border: 1px solid #000000;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #999999;
    justify-content: center;
    align-items: center;
  }
#parkingSpace-textbox[disabled] {
 background-color: #dfdfdf;
    caret-color: transparent;
}

.parking-info-card {
      position: relative;
        z-index: 0;
  }

  .parkingInfo-textarea {
    width: 90vw;
    
    height: 86px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 8px;
    border: 1px solid #000000;
    background-color: #ffffff;
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #999999;
    justify-content: center;
    align-items: center;
  }

  #parkingInfo-checkbox {
    background-color: #ffffff;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    text-align: left;
    line-height: 20px;
    justify-content: center;
    align-items: center;
  }

  #placeHolderText {
    font-family:"OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 14px ;
    color: #999999;
    text-align: left;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family:"OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 14px ;
    color: #999999;
    text-align: left;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-family:"OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 14px ;
    color: #999999;
    text-align: left;
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-family:"OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 14px ;
    color: #999999;
    text-align: left;
  }
  :-moz-placeholder { /* Firefox 18- */
    font-family:"OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 14px;
    color: #999999;
    text-align: left;
  }
  #close-window {
    width: 327px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    text-decoration: underline;
    color: #000000;
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.5px;
  }

  #errorMessage {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    color: #000000;
    text-align: center;
    line-height: 32px;
    letter-spacing: -0.5px;
  }

  /**
    Confirmation page
   */

.confirmation-text {
    margin-top: 20px;
}

.confirmation-text, .confirmation-text > div > div {
    font-size: 28px;
    font-family: "OpenSans-Regular", "Open Sans", sans-serif;
    font-weight: 700;
}

.desktop-cover {
    display: none;
}

.closeOMWModal {
    width: 100px;
    height: 48px;
    padding: 2px 2px 2px 2px;
    border-radius: 3px;
    border: 1px solid #000000;
    background-color: #000000;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    /*letter-spacing: -0.32px;*/
    font-size: 15px;
    border: 0px;
}

#feedback-survey-button {
    margin: auto;
    width: 230px;
    height: 48px;
    padding: 10px;
    border-radius: 3px;
    background-color: #000000 !important;
    box-sizing: border-box;
    font-family: 'futuraptbold';
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 24px;
    letter-spacing: -0.38px;
}

#smg-etr-popup {
    position: fixed !important;
    top: 50px !important;
    left: calc(50vw - 184px) !important;
    height: 380px !important;
}

#smg-etr-feedbacktab-activator {
    display: none !important;
}

.modal-close {
    font-size: 16px;
    font-weight: bold;
    font-family: 'futuraptbold';
    margin-bottom: 0px;
}

.modal-close:hover {
    cursor: pointer;
}


#pickup_time_info_bubble:hover, #oos_info_bubble:hover {
    cursor: pointer;
}

/**
Desktop Specific
 */
@media screen and (min-width: 800px) {

    body {
        background-color: #999999;
    }

    #root {
        margin-top: 100px !important;
        width: 374px;
        height: 667px;
        margin: auto;
        background-color: white;
    }
    .page-header, .page-footer {
        width: 374px;
    }

    .page-footer {
        top: 646px;
        left: calc(50% - 187px);
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .vehicle-footer {
        top: 694px;
    }

    .container-fluid {
        background-color: white;
    }

    .desktop-cover {
        display: block;
        position: fixed;
        top: 766px;
        background-color: #999999;
        height: 100vh;
        width: 100vw;
    }
    #vehicle-make {
        margin-bottom: 200px;
    }
    #root > div.overflow-hidden {
        background-color: white;
        height: 1800px;
    }
    .parkingInfo-textarea {
        width: 330px;
    }
}