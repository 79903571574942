.vehicle-number {
    background-color: white;
    position: relative;
    top: -15px;
    width: fit-content;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    color: grey;
}