#button-container {
    text-align: center;
}

.submit-button {
    margin-top:30px;
    background-color: black;
    color: white;
    font-family: "futuraptbold";
    border: 0px;
    width: 130px;
    padding: 10px;
    font-size: 15pt;
}

.cancel-button {
    margin-top:15px;
    font-family: "futuraptbold";
    background-color: lightgray;
    color: black;
    border: 0px;
    width: 130px;
    padding: 10px;
    font-size: 15pt;
}